[mol_float] {
	position: relative;
	z-index: 1;
	opacity: 1;
	transition: opacity .25s ease-in;
	display: block;
}

[mol_float_scrolling] {
	opacity: 0;
	transition: opacity .25s ease-out;
}
