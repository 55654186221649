[mol_check][disabled] > [mol_check_box_icon] {
	background-color: var(--mol_skin_passive);
}

[mol_check_box_icon] {
	left: 0;
	border-radius: var(--mol_skin_round);
	@apply --mol_skin_outline;
	color: var(--mol_skin_light_text);
}

[mol_check_box_icon] + [mol_check_box_label]:not(:empty) {
	margin-left: .5rem;
}

[mol_check]:not([mol_check_checked]) > [mol_check_box_icon] {
	fill: transparent;
}

[mol_check]:not([disabled]) > [mol_check_box_icon] {
	background: white;
}
