[mol_text] {
	line-height: 1.5;
	max-width: 60rem;
	background: var(--mol_skin_card);
	@apply --mol_skin_outline;
	padding: .5rem;
	border-radius: var(--mol_skin_round);
	white-space: pre-line;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 0 0 auto;
}

[mol_text_row] {
	margin: .5rem;
	overflow: auto;
	max-width: 100%;
}

[mol_text_type="block"] {
}

[mol_text_header] {
	display: block;
	padding: .5rem;
	font-weight: 500;
	margin: 0;
}

[mol_text_header_level="1"] {
	font-size: 1.5em;
}

[mol_text_header_level="2"] {
	font-size: 1.3em;
}

[mol_text_header_level="3"] {
	font-size: 1.1em;
}

[mol_text_header_level="4"] {
	font-size: 1.1em;
	font-style: italic;
}

[mol_text_header_level="5"] {
	font-size: 1.1em;
	font-weight: normal;
	font-style: italic;
}

[mol_text_type="list-item"] {
	display: list-item;
}

[mol_text_type="list-item"]:before {
	content: '•';
	margin-right: 1ch;
}

[mol_text_table] {
	max-width: 100%;
	max-height: 50vh;
	overflow: auto;
	margin: .5rem auto .5rem .5rem;
	flex-grow: 0;
}

[mol_text_type="code-indent"] ,
[mol_text_type="code"] {
	font-family: monospace;
	white-space: pre;
	border-radius: var(--mol_skin_round);
	padding: 1px;
}

[mol_text_type="text-link"] {
	color: var(--mol_skin_base);
	text-decoration: none;
}

[mol_text_link]:hover ,
[mol_text_link]:focus {
	text-decoration: underline;
	outline: none;
}

[mol_text_image] {
	max-width: 100%;
	max-height: 75vh;
	object-fit: scale-down;
}

[mol_text_type="strong"] {
	font-weight: bolder;
}

[mol_text_type="emphasis"] {
	font-style: italic;
}

[mol_text_type="strike"] {
	text-decoration: line-through;
	color: var(--mol_skin_passive_text);
}

[mol_text_type="code-keyword"] {
	color: #E06252;
}

[mol_text_type="code-field"] {
	color: #D9963F;
}

[mol_text_type="code-tag"] {
	color: #D9963F;
}

[mol_text_type="code-global"] {
	color: #61AFEF;
}

[mol_text_type="code-decorator"] {
	color: #4BB6A7;
}

[mol_text_type="code-punctuation"] {
	opacity: .5;
}

[mol_text_type="code-string"] {
	color: #98C363;
}

[mol_text_type="code-number"] {
	color: #E06252;
}

[mol_text_type="code-call"] {
	color: #C678DD;
}

[mol_text_type="code-comment-inline"] ,
[mol_text_type="code-comment-block"] {
	opacity: .5;
}

[mol_text_type="code-docs"] {
	opacity: .75;
}
