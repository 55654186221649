[mol_plot_dot] {
	stroke-linecap: round;
	stroke-width: 8px;
}

[mol_plot_dot_sample] {
	margin: 0 .5rem;
	padding: .25rem;
	border-radius: 1rem;
	background: currentColor;
	align-self: center;
}
