[mol_check] {
	flex: 0 1 auto;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
	border: none;
	font-weight: normal;
	box-shadow: none;
	text-align: left;
}

[mol_check]:not([mol_check_checked]) {
	/*background: transparent;*/
	color: inherit;
}

[mol_check_label] {
	display: inline-flex;
}
