[mol_row] {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	align-content: flex-start;
	justify-content: flex-start;
	padding: .5rem;
	flex: 1 1 auto;
	@apply --mol_skin_outline;
	border-radius: var(--mol_skin_round);
	box-sizing: border-box;
	max-width: 100%;
}

[mol_row] > * ,
[mol_row_sub] > * {
	margin: .5rem;
	max-width: 100%;
}

[mol_row_sub] {
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	flex: 1 1 auto;
}
