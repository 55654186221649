[mol_link] {
	text-decoration: none;
	color: var(--mol_skin_base);
	cursor: pointer;
	border-radius: var(--mol_skin_round);
	padding: .5rem;
	box-sizing: border-box;
}

[mol_link]:not([mol_link_current]):hover {
	background-color: var(--mol_skin_hover);
}

[mol_link]:not([mol_link_current]):focus {
	background-color: var(--mol_skin_hover);
	box-shadow: 0 0 0 1px var(--mol_skin_hover_line);
}

[mol_link_current] {
	background-color: var(--mol_skin_current);
	color: var(--mol_skin_current_text);
}

[mol_link]:focus {
	outline: none;
}
