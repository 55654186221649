[mol_button] {
	user-select: none;
	border: none;
	font: inherit;
	background-color: none;
	@apply --mol_skin_outline;
	border-radius: var(--mol_skin_round);
	justify-content: center;
	align-content: center;
	align-items: center;
	vertical-align: middle;
	text-align: center;
	padding: .5rem;
	display: inline-flex;
	text-decoration: inherit;
	color: inherit;
	cursor: inherit;
	position: relative;
	box-sizing: border-box;
}

[mol_button][disabled] {
	color: var(--mol_skin_passive_text);
	background: none;
	pointer-events: none;
}

[mol_button_minor] {
	background-color: var(--mol_skin_button);
	color: var(--mol_skin_light_text);
}

[mol_button_major] {
	background-color: var(--mol_skin_accent);
	color: var(--mol_skin_accent_text);
	@apply --mol_skin_outline;
}

[mol_button_danger] {
	background-color: var(--mol_skin_warn);
	color: var(--mol_skin_warn_text);
}

[mol_button]:hover {
	z-index: 1;
	cursor: pointer;
	background-color: var(--mol_skin_hover);
}

[mol_button_minor]:hover {
	background-color: var(--mol_skin_light_hover);
	color: var(--mol_skin_light_text);
}

[mol_button]:focus {
	z-index: 1;
	outline: none;
	@apply --mol_skin_focus;
}

[mol_button_major]:hover {
	background-color: var(--mol_skin_accent_hover);
}

[mol_button_danger]:hover {
	background-color: var(--mol_skin_warn_hover);
}
